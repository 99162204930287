@import "~@config/config.less";

.page-bottom {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .bottomLogoLyr {
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;

        .bottomLogo {
            width: 138px;
            height: 28px;
            align-self: center;
        }
    }

    .agreeLry {
        width: 100%;
        display: flex;
        flex-direction: row;

        .agreeTxt {
            margin-left: 5px;
            margin-right: 5px;
            text-align: center;
            font-size: 12px;
            color: #057eca;
            font-weight: 600;
        }
    }

    .rightTxt {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
    }

}