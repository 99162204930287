@import "~@config/config.less";

.gamelist-page {
    width: 100%;
    min-height: 100vh;
    background-color: @color_bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

    .sticky {
        width: 100%;
        background-color: @color_theme;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .navBarLay {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .tmpLyr {
            width: 100%;
            height: 60px;
            position: relative;

            .btnLyr {
                width: 100px;
                height: 60px;
                position: absolute;
                z-index: 10;
                left: 0px;
                top: 0px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                .img {
                    width: 20px;
                    height: 20px;
                    margin-top: 20px;
                    margin-left: 15px;
                }

            }

            .titleLyr {
                width: 100%;
                height: 60px;
                position: absolute;
                z-index: 2;
                left: 0px;
                top: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                    margin-top: 10px;
                    color: white;
                    font-size: 28px;
                    font-weight: 700;
                }
            }
        }
    }


    .navBar {
        width: 90%;

        .adm-nav-bar-back-arrow {
            color: white;
        }

        .adm-nav-bar-title {
            color: white;
            font-size: 28px;
            font-weight: 700;
        }
    }

    .container {
        width: 95%;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .listLyr {
            width: 100%;
            margin-bottom: 0px;

            .adm-list-body {
                background-color: transparent !important;
            }

            .adm-list-item {
                background-color: transparent !important;
            }

            .adm-list-item-content {
                border-top: 0px;
            }

            .gpt-adLyr {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .rowLyr {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .infiniteScroll {
            padding: 0 !important;
            height: 30px !important;
        }
    }


}