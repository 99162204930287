@import "~@config/config.less";

.privacy-page {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: @color_bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

    .sticky {
        width: 100%;
        background-color: @color_theme;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .navBar {
        width: 90%;

        .adm-nav-bar-back-arrow {
            color: white;
        }

        .adm-nav-bar-title {
            color: white;
            font-size: 28px;
            font-weight: 700;
        }
    }

    .privacyif {
        flex: 1;
    }

    .descTx {
        white-space: pre-wrap;
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 15px;
        margin-left: 10px;
        margin-right: 10px;
    }
}