@import "~@config/config.less";

.gameplay-page {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: @color_bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

    .sticky {
        width: 100%;
        background-color: @color_theme;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .navBar {
        width: 90%;

        .adm-nav-bar-back-arrow {
            color: white;
        }

        .adm-nav-bar-title {
            color: white;
            font-size: 28px;
            font-weight: 700;
        }
    }

    .gameif {
        flex: 1;
    }
}

.overlayContent {
    position: absolute;
    top: 0%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 550px;
    height: 150px;
    margin-top: -75px;
    margin-left: -75px;
    background: white;
    border-radius: 16px;
}