@import "~@config/config.less";

.__IconLyr {
    width: 45%;
    background-color: white;
    border-radius: @border_radius;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05);

    .nameTx {
        max-width: 150px;
        font-size: 15px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .starLyr {
        display: flex;
        flex-direction: row;
        padding-left: 5px;
        padding-bottom: 5px;
    }
}