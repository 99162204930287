.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}