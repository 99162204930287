@import "~@config/config.less";

.home-page {
    width: 100%;
    min-height: 100vh;
    background-color: @color_bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

    .sticky {
        width: 100%;
        background-color: @color_theme;
        position: sticky;
        top: 0;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navBarLay {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .tmpLyr {
            width: 100%;
            height: 60px;
            position: relative;

            .btnLyr {
                width: 100px;
                height: 60px;
                position: absolute;
                z-index: 10;
                left: 0px;
                top: 0px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                .img {
                    width: 20px;
                    height: 20px;
                    margin-top: 20px;
                    margin-left: 15px;
                }

            }

            .logoLyr {
                width: 100%;
                height: 60px;
                position: absolute;
                z-index: 2;
                left: 0px;
                top: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .logo {
                    width: 192px;
                    height: 39px;
                    margin-top: 10px;
                }
            }
        }
    }

    .swiperLyr {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-content: center;

        .swiperItem {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .bannerLyr {
                width: 90%;
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: @border_radius;
                overflow: hidden;
                box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05);

                .msgLyr {
                    width: 100%;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .nameTx {
                        font-size: 16px;
                        font-weight: 600;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        overflow: hidden;
                        padding-left: 10px;
                    }

                    .starLyr {
                        display: flex;
                        flex-direction: row;
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    .divider {
        width: 90%;
    }
}

.boxLyr {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titleLyr {
        width: 100%;
        height: 30px;
        line-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .titleTx {
            font-size: 20px;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
            text-align: left;
        }

        .moreTx {
            font-size: 18px;
            font-weight: 620;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
            text-align: right;
            color: #057eca;
        }
    }

    .rowLyr {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
    }

}