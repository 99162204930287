@import "~@config/config.less";

.myMenuLyr {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .menuItem {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;

        .icon1 {
            width: 20px;
            height: 20px;
            margin-left: 14px;
        }

        .icon2 {
            width: 20px;
            height: 14px;
            margin-left: 14px;
        }

        .icon3 {
            width: 20px;
            height: 22px;
            margin-left: 14px;
        }

        .icon4 {
            width: 20px;
            height: 20px;
            margin-left: 15px;
        }

        .icon5 {
            width: 20px;
            height: 14px;
            margin-left: 14px;
        }

        .icon6{
            width: 20px;
            height: 20px;
            margin-left: 14px;
        }

        .txt {
            font-size: 16px;
            margin-left: 10px;
            font-weight: 600;
        }
    }
}