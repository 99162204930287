@import "~@config/config.less";

.details-page {
    width: 100%;
    min-height: 100vh;
    background-color: @color_bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

    .sticky {
        width: 100%;
        background-color: @color_theme;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .navBar {
        width: 90%;

        .adm-nav-bar-back-arrow {
            color: white;
        }

        .adm-nav-bar-title {
            color: white;
            font-size: 28px;
            font-weight: 700;
        }
    }

    .gameLyr {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .iconLyr {
            width: 216px;
            height: 250px;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;

            .icon {
                width: 216px;
                height: 172px;
                border-radius: @border_radius;
                box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
            }
        }

        .btnLyr {
            width: 250px;
            height: 65px;
            line-height: 65px;
            background-color: @color_theme;
            display: flex;
            flex-direction: row;
            align-content: center;
            border-radius: @border_radius;
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);

            .playTx {
                width: 130px;
                font-size: 25px;
                font-weight: 600;
                color: white;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                overflow: hidden;
                margin-top: 18px;
                margin-left: 50px;
            }

            .playIc {
                width: 25px;
                height: 28px;
                margin-top: 18px;
            }
        }

        .infoLyr {
            width: 90%;
            background-color: white;
            display: flex;
            flex-direction: column;
            border-radius: @border_radius;
            overflow: hidden;
            margin-top: 25px;
            margin-bottom: 20px;
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05);

            .nameTx {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 25px;
                font-weight: 600;
                margin-top: 5px;
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                overflow: hidden;
            }

            .starLyr {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                margin-left: 5px;
            }

            .descTx {
                white-space: pre-wrap;
                font-size: 15px;
                margin-top: 10px;
                margin-bottom: 15px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .hotLyr {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .titleLyr {
            width: 100%;
            height: 30px;
            line-height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .titleTx {
                font-size: 20px;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                overflow: hidden;
                text-align: left;
            }

            .moreTx {
                font-size: 18px;
                font-weight: 620;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                overflow: hidden;
                text-align: right;
                color: #057eca;
            }
        }

        .rowLyr {
            width: 100%;
            height: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: space-between;
        }

    }
}