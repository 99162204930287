@import "~@config/config.less";

.tabbar-component {
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  box-sizing: border-box;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 1));
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 999;

  .tabbar-div {
    display: flex;
    flex: 1;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 10px;
      margin-top: 3px;
    }
    .unsel {
      color: @TAB_COLOR_TEXT_UNSEL;
    }
    .sel {
      color: @TAB_COLOR_TEXT_SEL;
    }
    .unread-tag {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #E54552;
      right: calc(50% - 15px);
      top: 10px;
    }
  }
}